p {
  color: black !important;
}
.stcky {
  position: fixed;
  padding: 10px;
  z-index: 1;
}
.mrgn {
  margin-right: 320px !important;
}
@media only screen and (max-width: 1198px) and (min-width: 1024px) {
  .mrgn {
    margin-right: 140px !important;
  }
}
.bnrimgmrgn {
  margin-top: -32px;
}
.zminus {
  z-index: -50;
}
.justfy {
  text-align: justify !important;
}
.logocolor1 {
  color: #82b3d7 !important;
}
.logocolor2 {
  color: #21518e !important;
}
.clrservicettile {
  color: #243e63 !important;
}
.blckcolor {
  color: black !important;
}
.logoclr1bg {
  background-color: #21518e !important;
}
.logoclr2bg {
  background-color: #82b3d7 !important;
}
.logoclr1bg:hover {
  background-color: #82b3d7 !important;
}
.bannerImage {
  height: 550px;
  width: 100%;
}
a:hover {
  color: #82b3d7 !important;
}

@media only screen and (max-width: 1000px) {
  .bannerImage {
    height: 300px;
    width: 100%;
  }
}

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}
.img4 {
  height: 200px;
}
.margin {
  margin-top: -50px;
}

.img {
  position: absolute;
  left: 50%;
}
.singleColumn {
  width: 370px;
}
@media only screen and (max-width: 600px) {
  .singleColumn {
    width: 250px;
  }
}
.fiveColumn {
  margin-right: -30px;
}
.fiveColumn1 {
  margin-right: -30px;
}
@media only screen and (max-width: 600px) {
  .bannerImage {
    height: 300px;
    width: 100%;
  }
  .img1 {
    width: 300px;
    height: 86px;
  }
}

.img2 {
  width: 100px;
  display: inline-block;
}

.service {
  /* color: rgb(34, 81, 143) !important; */
  color: red;
}
